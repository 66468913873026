.center {
  padding-left: 10px;
}
a {
  text-decoration: none;
}
#Header {
  background-color: #fff;
  border: 1px solid #888;
}
.navbar-right-admin {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 10px;
}
.sub-admin-flex {
  display: flex;
  flex-direction: column;
}
.cl-custom-check {
  display: none;
}

.cl-custom-check-label {
  background-color: #ccc;
  color: #fff;
  padding: 5px 10px;
  font-family: sans-serif;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  display: inline-block;
  margin: 0 10px 10px 0;
  backface-visibility: hidden;
  transition: all 0.6s ease;
}
.blast_animtion {
  margin-top: 20px;
  margin-left: 20px;
  border: 1px solid #000;
  padding: 10px 10px;
  text-align: center;
}
.blast_animtion_color span {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
.blast_animtion_color button {
  border: none;
  outline: none;
  padding: 4px 20px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #7451f8;
  color: #fff;
}
.cl-custom-check:checked + .cl-custom-check-label {
  background-color: #0a0;
  backface-visibility: hidden;
  transform: rotateY(360deg);
}
.welcome-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.welcome-page h1 img {
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
}
.bg-color-change {
  background: #fff;
}
.navbar-brand-admin {
  color: #000;
  padding: 10px 10px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.navbar-brand-admin:hover {
  color: #000;
}
.center ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar .center ul .title {
  font-size: 10px;
  font-weight: bold;
  color: #999;
  margin-top: 15px;
  margin-bottom: 5px;
}
.center ul li {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.center ul li .icon {
  font-size: 18px;
  height: 27px;
  width: 27px;
  color: #7451f8;
}
.center ul li span {
  font-size: 13px;
  font-weight: 600;
  color: #888;
  margin-left: 10px;
}
.widgets,
.charts {
  display: flex;
  padding: 20px;
  gap: 20px;
}

.widget {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  padding: 10px 10px;
  box-shadow: 2px 4px 10px 1px rgb(85 81 81 / 47%);
  border-radius: 10px;
  height: 100px;
}
.widget .left,
.widget .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.widget .left .title,
.widget .right .title {
  font-weight: bold;
  font-size: 14px;
  color: rgb(160, 160, 160);
}
.widget .left .counter,
.widget .right .counter {
  font-size: 28px;
  font-weight: 300;
  color: #000;
}
.widget .left .link,
.widget .right .link {
  width: max-content;
  font-size: 12px;
  border-bottom: 1px solid gray;
  color: #000;
}
.percentage.positive svg path {
  fill: #000;
}
.widget .left .percentage.positive,
.widget .right .percentage.positive {
  color: green;
}

.widget .left .percentage,
.widget .right .percentage {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.widget .left .icon,
.widget .right .icon {
  font-size: 18px;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
  height: 30px;
  width: 30px;
}

.percentage.positive svg {
  width: 1em;
  height: 1em;
}

.charts {
  padding: 5px 20px;
  display: flex;
  gap: 20px;
}
.featured {
  flex: 2 1;
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  padding: 10px;
}
.featured .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
}
.featured .top .title {
  font-size: 16px;
  font-weight: 500;
}
.featured .top svg {
  width: 1rem;
  height: 1rem;
}
.featured .bottom {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.featured .bottom .featuredChart {
  width: 100px;
  height: 100px;
}
.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}
.featured .bottom .title {
  font-weight: 500;
  color: gray;
}
.featured .bottom .amount {
  font-size: 30px;
}
.featured .bottom .desc {
  font-weight: 300;
  font-size: 12px;
  color: gray;
  text-align: center;
}
.featured .bottom .summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.featured .bottom .summary .item {
  text-align: center;
}
.featured .bottom .summary .item .itemTitle {
  font-size: 14px;
  color: gray;
}
.itemResult.negative {
  color: red;
}
.itemResult.negative svg {
  height: 1rem;
  width: 1rem;
}
.itemResult.negative {
  color: red;
}
.itemResult.positive {
  color: green;
}
.itemResult {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  justify-content: center;
}
.itemResult.positive {
  color: green;
}
.current_profit {
  position: absolute;
  top: -12px;
  right: -13px;
}
.current_profit i {
  color: red;
  font-size: 10px;
}
.current_profit {
  position: absolute;
}
.itemResult.positive svg {
  height: 1rem;
  width: 1rem;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}
.set_dummy_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.set_dummy_button button {
  width: 100px;
}
#dummyAddData .modal-dialog {
  margin: 1.5rem auto;
  max-width: 760px;
}
#dummyAddData .modal-content {
  background: #fff;
  border: 2px solid #fff;
}
.col-md-6.dummy_AddData {
  margin-bottom: 10px;
}
.col-md-6.dummy_AddData label {
  font-size: 18px;
  color: grey;
  font-weight: 600;
  margin-bottom: 6px;
}
.col-md-6.dummy_AddData input {
  font-size: 14px;
  color: grey;
  font-weight: 400;
  width: 100%;
  border: 1px solid grey;
  padding: 10px 10px;
}
.pagination-color-change ul li {
  color: #000;
}
.pagination-color-change ul li a {
  color: #000;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}
.payment-input-change {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  border: 1px solid #999;
  width: 100%;
  padding: 10px 10px;
}
.chart {
  flex: 4 1;
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  padding: 10px;
  color: gray;
}
.listContainer {
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  padding: 20px;
  margin: 20px;
}
/* .Qr-input-add {
  margin: 20px 0px;
} */
.Qr-input-add input {
  font-size: 14px;
  margin: 10px;
}
.listContainer .listTitle {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}
.table .cellWrapper .image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  width: 100%;
  overflow-x: auto;
}
.css-quj9j6-MuiTable-root {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 650px;
}
.css-15wwp11-MuiTableHead-root {
  display: table-header-group;
}
.css-apqrd9-MuiTableBody-root {
  display: table-row-group;
}
.css-1q1u3t4-MuiTableRow-root {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}
.css-1ygcj2i-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px;
  color: #000;
}
.css-1q1u3t4-MuiTableRow-root {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}
.css-1ex1afd-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px;
}
.table .status.Approved {
  color: green;
  background-color: rgba(0, 128, 0, 0.151);
}
.table .status {
  padding: 5px;
  border-radius: 5px;
}
.table .status.Pending {
  color: goldenrod;
  background-color: rgba(189, 189, 3, 0.103);
}

.datatable {
  height: 600px;
  padding: 20px;
}
.datatable .datatableTitle {
  width: 100%;
  font-size: 24px;
  color: gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datatable .datatableTitle .link {
  text-decoration: none;
  color: green !important;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid green;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.css-1w5m2wr-MuiDataGrid-virtualScroller {
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.datatable .cellWithImg .cellImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.datatable .cellWithImg {
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
}
.datatable .cellWithStatus.active {
  background-color: rgba(0, 128, 0, 0.05);
  color: green;
}

.cellAction {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
}
.cashout-input-value {
  width: 100%;
  position: relative;
}
.cashout-input-value input {
  width: 100%;
  border: 1px solid #000;
  padding: 6px 10px;
  border-radius: 10px;
  font-weight: 400;
  position: relative;
  font-size: 14px;
}
.cashout-input-value button {
  padding: 2px 6px;
  top: 4px;
  right: 7px;
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  border: 1px solid #908d8d;
  outline: none;
}
.datatable .cellWithStatus {
  padding: 5px;
  border-radius: 5px;
}
.datatable .cellWithStatus.passive {
  background-color: rgba(255, 0, 0, 0.05);
  color: crimson;
}
.datatable .cellWithStatus.pending {
  background-color: rgba(255, 217, 0, 0.05);
  color: goldenrod;
}
.cellAction .viewButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: darkblue;
  border: 1px dotted rgba(0, 0, 139, 0.596);
  cursor: pointer;
}
.cellAction .viewButton1 {
  padding: 2px 5px;
  border-radius: 5px;
  color: rgb(3, 164, 40);
  border: 1px dotted rgba(12, 181, 63, 0.596);
  cursor: pointer;
}
.cellAction .deleteButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: crimson;
  border: 1px dotted rgba(220, 20, 60, 0.6);
  cursor: pointer;
}
.top {
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  padding: 10px;
  margin: 20px;
  display: flex;
}
.top h1 {
  color: lightgray;
  font-size: 20px;
}
.bottom {
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  padding: 10px;
  margin: 20px;
  display: flex;
}
.bottom .left {
  flex: 1 1;
  text-align: center;
}
.bottom .left img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.bottom .right {
  flex: 2 1;
}

.bottom .right form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-around;
}
.bottom .right form .formInput {
  width: 40%;
}
.bottom .right form .formInput label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
}
.bottom .right form .formInput label .icon {
  cursor: pointer;
}
.bottom .right form .formInput input {
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid gray;
  font-size: 14px;
}
.bottom .right form button {
  width: 150px;
  padding: 10px;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}
.user-information {
  padding: 20px;
  display: flex;
  gap: 20px;
}
.user-information .left {
  flex: 1 1;
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  padding: 20px;
  position: relative;
}
.user-information .left .editButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 12px;
  color: #7451f8;
  background-color: rgba(117, 81, 248, 0.0941176471);
  cursor: pointer;
  border-radius: 0px 0px 0px 5px;
}

.user-information .title {
  font-size: 16px;
  color: lightgray;
  margin-bottom: 20px;
}
.user-information .left .item {
  display: flex;
  gap: 20px;
}
.user-information .left .item .itemImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.user-information .left .item .details .itemTitle {
  margin-bottom: 10px;
  color: #555;
}
.left .item .details .detailItem {
  margin-bottom: 10px;
  font-size: 14px;
}
.left .item .details .detailItem .itemKey {
  font-weight: bold;
  color: gray;
  margin-right: 5px;
}
.left .item .details .detailItem .itemValue {
  font-weight: 300;
  color: #000;
}
.user-information .right {
  flex: 2 1;
}
.admin_login_page {
  height: 100vh;
}
.admin_login_page_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.admin_login_page_bg {
  background: #141a2e;
  padding: 40px 40px;
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
  margin-top: 20px;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 13px 10px 13px !important;
}
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(255 255 255 / 60%) !important;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgb(255 255 255 / 60%) !important;
}
.admin_login_center {
  display: flex;
  justify-content: end;
  margin-top: 25px;
}
.col-lg-6.admin_login_page_bg h1 {
  text-align: center;
  font-size: 31px;
  font-weight: 600;
  margin-bottom: 10px;
}
.col-lg-6.admin_login_page_bg h6 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: #fff !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(255 255 255 / 60%) !important;
}
.status.accept {
  color: green;
  background-color: rgba(0, 128, 0, 0.151);
  padding: 2px 10px;
  border-radius: 5px;
}
.status.pending {
  color: goldenrod;
  background-color: rgba(189, 189, 3, 0.103);
  padding: 2px 10px;
  border-radius: 5px;
}
.status.reject {
  color: rgb(209, 0, 0);
  background-color: rgba(176, 41, 31, 0.151);
  padding: 2px 10px;
  border-radius: 5px;
}
.model-image-payment {
  background: transparent;
  border: none;
  box-shadow: none;
  text-align: center;
  pointer-events: none;
}
.view-page-details {
  color: rgb(209, 0, 0);
  background-color: rgba(176, 41, 31, 0.151);
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.title-withdraw {
  font-size: 16px;
  color: lightgray;
  margin-bottom: 20px;
}
.withdraw-space {
  padding: 20px 20px;
}
.withdraw-space1 {
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
}
.gameplay-space {
  padding: 20px 20px;
}
.gameplay-space-flex input {
  padding: 10px 10px;
  border: 1px solid;
  font-size: 14px;
  margin-bottom: 12px;
}
.deposite-view-flex {
  display: flex;
  justify-content: space-between;
}

.deposite-view-flex-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.editAmount {
  color: #000;
  margin-left: 20px;
  padding: 2px;
  font-weight: 500;
  border: 1px solid #000;
  cursor: pointer;
}
.editAmount i {
  padding-left: 6px;
}
.detailItem input {
  color: #000;
  font-size: 14px;
  border: 1px solid #000;
  padding: 6px 6px;
}
.detailItem button {
  padding: 6px;
  outline: none;
  border: none;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  margin-left: 6px;
}
@media screen and (max-width: 769px) {
  .deposite-view-flex {
    display: block;
  }
  .deposite-view-flex-img {
    margin-top: 20px;
  }
  .withdraw-space1 {
    display: block;
  }
  div div.left {
    margin-top: 20px;
  }
  .charts {
    display: block;
  }
  .widgets,
  .charts {
    display: block;
  }
  .widget {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .featured .bottom .summary {
    display: block;
  }
  .itemResult {
    justify-content: center;
  }
}
