.page404_content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
}
.page404_contain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
.upgrading_h1 {
  font-weight: 400;
  font-size: calc(2vw + 11px);
  text-transform: uppercase;
  color: #fdda5d;
}
.under_h1 {
  font-weight: 600;
  font-size: calc(4vw + 16px);
  text-transform: uppercase;
  color: #ffffff;
}
.page404_img {
  max-width: 100%;
  max-height: 603px;
  width: 768px;
  height: auto;
}

@media screen and (max-width: 1300px) {
  .page404_img {
    max-height: 400px;
    width: 400px;
  }
}
