* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Space Grotesk", sans-serif !important;
  color: #fff;
  overflow-x: hidden;
}
.App {
  background: black;
}
input {
  background: transparent !important;
}

input.form-control:-webkit-autofill {
  -webkit-background-clip: text !important;
  color: white !important;
  -webkit-text-fill-color: #6c7990 !important;
}
select.form-select {
  padding: 0 !important;
  border: 2px solid #2a3763;
}
select.form-select option {
  background-color: #1b1c1d;
  padding-left: 5px;
}
select.form-select option:not(:checked) {
  background-color: #1b1c1d;
}
select.form-select option:checked {
  background-color: #fff !important;
  color: #090f1e;
}
p {
  color: #8999b0;
  font-size: 16px;
  margin-bottom: 0px !important;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  font-size: 14px;
}
/* / Chrome, Safari, Edge, Opera / */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
}
.text-yellow {
  color: #eac954;
}
.text-yellow a {
  color: #eac954 !important;
  text-decoration: none;
}
input,
.input {
  border: 0;
  background: transparent;
  color: #6c7990;
  font-size: 16px;
  font-weight: 600;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input,
.input.inputID {
  color: #fff;
}
input,
.input.inputID::placeholder {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  opacity: 1;
}
input,
.input::placeholder {
  font-size: 20px;
  font-weight: 600;
  color: #6c7990;
  opacity: 1;
}
input,
.input:focus-visible {
  outline: 0;
}
a {
  color: #ffffff;
  text-decoration: none;
}
.display-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.display-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
/* model */
.modal-body {
  padding: 27px 40px 16px 10px;
}
.modal-dialog {
  overflow: hidden;
  max-width: 1186px;
  margin: 1.5rem;
}
#exampleModal4 .modal-dialog {
  max-width: 671px;
  margin: 1.5rem;
}
.navbar-brand:hover {
  color: #fff;
}
#paymentHistory .modal-dialog {
  max-width: 803px;
  margin: 1.5rem auto;
}
#PersonalInformation .modal-dialog,
#TermCondition .modal-dialog {
  max-width: 1784px;
  margin: 1.5rem;
  position: relative;
  top: 56px;
}
#Support .modal-dialog {
  max-width: 760px;
  margin: 1.5rem;
}
.bets_sections {
  position: relative;
}
.connection-issue {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connection-issue p {
  background: #090f1e;
  padding: 10px 20px;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-118%);
  transition: 0.5s;
  position: absolute;
}
.connection-issue p.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(51%);
  z-index: 1;
}
.net-connection {
  display: flex;
  align-items: center;
  background: #04102d;
  padding: 8px 20px;
  border-radius: 27px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-118%);
  transition: 0.5s;
  position: absolute;
}
.net-connection.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
}
.net-connection-icon {
  padding-right: 17px;
}
.net-connection-icon i {
  background: #e89393;
  border-radius: 27px;
  color: #af0d0d;
  height: 36px;
  display: flex;
  width: 36px;
  justify-content: center;
  align-items: center;
}
.net-connection-show h4 {
  font-size: 18px;
  font-weight: 900;
}
.net-connection-show p {
  font-size: 13px;
  font-weight: 800;
}
.winning-amount {
  display: flex;
  padding: 5px 15px 5px 0px;
  align-items: center;
  background-color: #1b1c1d;
  border-radius: 20px;
  position: absolute;
  left: 20px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-118%);
  transition: 0.5s;
}
.winning-amount.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
}
.winning-amount-bet {
  padding: 10px 30px;
}
.winning-amount-pick {
  background:linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  ),
    #222d47;
  border-radius: 20px;
  padding: 4px 20px;
}
.winning-amount-pick p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.winning-amount-pick h5 {
  font-size: 18px;
  font-weight: 600;
}
.winning-amount-bet h4 {
  font-size: 18px;
  font-weight: 600;
}
@media (min-width: 790px) {
  #Support .modal-dialog {
    margin: 1.5rem auto;
  }
  #PersonalInformation .modal-dialog,
  #TermCondition .modal-dialog {
    max-width: 1784px;
    margin: 1.5rem;
    position: relative;
    top: 0;
  }
}
@media (min-width: 700px) {
  #exampleModal4 .modal-dialog {
    margin: 1.5rem auto;
  }
}
@media (min-width: 800px) {
  #paymentHistory .modal-dialog {
    margin: 1.5rem auto;
  }
}
@media (min-width: 1200px) {
  .modal-dialog,
  #paymentHistory .modal-dialog {
    margin: 1.5rem auto;
  }
}
.user_title_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 60px !important;
}
.numberSets {
  font-size: 10px !important;
  vertical-align: middle;
}
.user_title span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 35px;
}
.loader-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  outline: 0;
  /* width: 1186px;
  height: 1170px; */
  background: #222d47;
  border: 2px solid #2a3763;
  box-shadow: 0px 0px 20px rgba(117, 9, 139, 0.54);
  border-radius: 30px;
  padding: 22px;
}
#paymentHistory .modal-content {
  max-height: 888px;
  overflow: scroll;
  scrollbar-width: 0;
}
.modal-content::-webkit-scrollbar {
  display: none;
  scrollbar-width: 0;
}
.modal-content::-moz-scrollbar {
  display: none;
  scrollbar-width: 0;
}
/* deposit */
.deposit-left {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
}
.model_close {
  cursor: pointer;
}
.model_left_img {
  position: absolute;
  bottom: 0;
  left: 0;
}
.deposit-left-top,
.payment_history_top {
  display: flex;
  column-gap: 18px;
  align-items: center;
}
.deposit-left-top img,
.payment_history_top img {
  right: -14px;
  top: -14px;
  position: absolute;
}
.deposit_option {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.deposit_option a {
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  width: 94px;
}
.deposit_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: 2px solid #1b1c1d;
  outline-offset: 4px;
  background: #1b1c1d;
  mix-blend-mode: normal;
  border-radius: 15px;
  padding: 30px;
}
.deposit_options img {
  width: 150px;
}
.deposit_options.active {
  outline: 2px solid #ffd700;
  outline-offset: 4px;
  color: black;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  ),
    #1b1c1d;
  mix-blend-mode: normal;
  border-radius: 15px;
  padding: 30px;
}
/* .deposit_options.paytm_deposit{
  outline: 2px solid #1b1c1d;
  outline-offset: 4px;
  background: #1b1c1d;
  mix-blend-mode: normal;
  border-radius: 15px;
  padding: 30px;
} */
.deposit-detail {
  display: flex;
  flex-direction: column;
  /* row-gap: 41px; */
  position: relative;
  padding: 24px 18px;
  margin-top: 32px;
}
.deposit-right {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
}
.deposit-detail::before {
  content: "";
  background: url("../public/images/model-right-bottom.png") no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 75px;
  left: -49px;
  width: 96px;
  height: 227px;
}
.deposit-right::after {
  content: "";
  background: url("../public/images/model_right_top.png") no-repeat;
  background-size: contain;
  position: absolute;
  top: 52px;
  right: -30px;
  width: 96px;
  height: 227px;
  z-index: -1;
}
.deposit_right_img,
.login_bgg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
}
.deposit-right-top .id {
  background: #1b1c1d;
  border-radius: 7px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 134px;
}
.bank_account_style {
  background: #1b1c1d;
  border-radius: 15px;
  column-gap: 30px;
  margin: 14px 0px;
  height: 215px;
  position: relative;
}
.bank_account_name {
  border: 1px solid #222d47;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bank_account_name div {
  display: flex;
  align-items: center;
}
.bank_account_name p {
  font-weight: 400;
  font-size: 18px;
  color: #eac954;
  padding-right: 8px;
}
.upi_id_style {
  background: #1b1c1d;
  border-radius: 15px;
  display: flex;
  column-gap: 30px;
  justify-content: center;
  margin: 14px 0px;
  height: 130px;
  position: relative;
  padding: 14px 20px;
}
.upi_id_style_qr {
  background-color: #fff;
}
.upi_id_style_qr img {
  max-width: 143px;
  max-height: 143px;
  height: 100%;
}
.upi_id_style_p h2 {
  font-weight: 500;
  font-size: 24px;
  color: #eac954;
}
.upi_id_style_p p {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}
.upi_id {
  background: #1b1c1d;
  border-radius: 15px;
  padding: 18px;
  display: flex;
  column-gap: 30px;
  position: relative;
  height: 70px;
  align-items: center;
  margin: 14px 0px;
}
.upi_id.id_text {
  margin-top: 26px;
}
.upi_id .upi_text {
  color: #eac954;
  padding-right: 6px;
  font-size: 14px;
}
.upi_id .upi_text_rupay {
  color: #8999b0;
}
.upi_id .copy-btn {
  margin-left: auto;
  height: 37px;
  width: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-range {
  position: absolute;
  bottom: -30px;
  right: 8px;
}
.back_payment.back-arrow {
  position: relative;
  text-align: right;
  display: flex;
  justify-content: right;
  padding: 18px;
  top: 0px;
}
.modal-dialog .form-group.floating > label {
  left: 31px;
  color: #6c7990;
  position: absolute;
  background-color: transparent;
  padding: 0px 5px 0px 5px;
  font-size: 14px;
  transition: 0.1s;
  pointer-events: none;
  font-weight: 500 !important;
  transform-origin: bottom left;
  /* background: #1c263d !important; */
  background: transparent !important;
}
.modal-dialog .form-control.floating:focus ~ label {
  transform: translateY(-32px) scale(0.8);
  opacity: 1;
  color: #6c7990;
  box-shadow: none;
}
.modal-dialog .form-control.floating:valid ~ label {
  transform: translateY(-32px) scale(0.8);
  opacity: 1;
  box-shadow: none;
}
.modal-dialog .form-group.floating.uploadImg > label {
  left: 71px;
  color: #6c7990;
  position: absolute;
  background-color: transparent;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.1s;
  pointer-events: none;
  font-weight: 500 !important;
  transform-origin: bottom left;
  background: #1c263d !important;
}
.modal-dialog .form-control.floating.uploadImgTExt:focus ~ label {
  transform: translateY(-32px) translateX(-30px) scale(0.8);
  opacity: 1;
  color: #6c7990;
  box-shadow: none;
}
.modal-dialog .form-control.floating.uploadImgTExt:valid ~ label {
  transform: translateY(-32px) translateX(-30px) scale(0.8);
  opacity: 1;
  box-shadow: none;
}
.modal-dialog .form-control {
  width: 95% !important;
  margin-left: auto;
}
.modal-dialog .form-group.floating {
  display: flex;
  column-gap: 10px;
  border: 2px solid #2a3763;
  border-radius: 52.5px;
  position: relative;
  padding: 3px;
  align-items: center;
  height: 60px;
  margin: 14px 0px;
}
.uploadImg .mail_img {
  margin-left: 25px;
}
.show-rupey-icon {
  position: absolute;
  left: 24px;
  top: 19px;
  font-size: 14px;
}
.modal-dialog .mail_img {
  width: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.main-btn.next-page {
  color: black;
  outline: 2px solid #ffd700;
  outline-offset: 4px;
  z-index: 1;
  position: relative;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  ),
    #d9d9d9;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-btn.next-page:focus:not(:focus-visible) {
  outline: 2px solid #ffd700;
  outline-offset: 4px;
}

.uploadClick {
  position: relative;
}
.show-icon-respon {
  display: none;
}
.deposite-amount {
  text-align: center;
}
.deposite-amount-btn {
  font-size: 14px;
  color: black;
  text-align: center;
  font-weight: 500;
  outline: 1.5px solid #ffd700;
  outline-offset: 1.5px;
  padding: 12px 50px;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  ),
    #222d47;
  border-radius: 19.5px;
  border: none;
}
.uploadClick #FileClick {
  position: absolute;
  top: 1px;
  width: 60px;
  height: 61px;
  right: 8px;
  opacity: 0;
  z-index: 1000;
}
.uploadClick #FileClickPAYTM {
  position: absolute;
  top: 1px;
  width: 60px;
  height: 61px;
  right: 8px;
  opacity: 0;
  z-index: 1000;
}
.model_middle_line,
.condition_middle_line {
  display: flex;
  justify-content: center;
}

@media (max-width: 991px) and (min-width: 767px) {
  .deposit_option {
    display: flex;
    flex-direction: row;
    row-gap: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .model_middle_line,
  .condition_middle_line {
    display: none;
  }
  .modal-content {
    padding: 0px;
  }
  .deposit-left {
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    margin-bottom: 22px;
  }
  .deposit_options {
    column-gap: 20px;
  }
  .back_payment.back-arrow {
    top: 4px;
  }
  .deposit-detail {
    row-gap: 27px;
  }
  .back_payment.back-arrow {
    top: 0px;
  }
}
@media (max-width: 767px) {
  .model_middle_line,
  .condition_middle_line {
    display: none;
  }
  .deposit-left {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-bottom: 12px;
  }

  .back_payment.back-arrow {
    top: 0;
  }
  .modal-content {
    padding: 0px;
  }
}
.payment-btns {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  position: relative;
  margin: 35px 0px 4px 0px;
}
.main-btn.payment-btn {
  width: 121px;
  height: 50px;
  background: transparent;
  border: 1.5px solid #2a3763;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 500px) {
  .deposit_options.upi_deposit,
  .deposit_options.paytm_deposit {
    padding: 10px 30px 10px 10px;
  }
}
/* payment history */
.model_hr {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  top: 133px;
}
.wallet_id.id {
  width: 179px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1b1c1d;
  border-radius: 7px;
  margin-left: auto;
}
.login-btn-imges {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  object-fit: cover;
}
.modal-dialog h1 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #eac954;
}
/* how play pop */
.how_to_play {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.how_to_play_name {
  display: flex;
  align-items: center;
}
.how_to_play_name img {
  padding-right: 10px;
}
.how_to_play_name h2 {
  text-transform: uppercase;
  color: white;
  font-size: 20px;
  font-weight: 600;
}
.how_to_play_info {
  background: #1b1c1d;
  padding: 18px 20px;
  margin-top: 14px;
  border-radius: 20px;
}
.how_to_play_info p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(223, 229, 242, 0.7);
}

.how_to_play_show {
  height: 210px;
  width: 100%;
  background: rgba(223, 229, 242, 0.1);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
}
.how_to_play_image {
  width: 100%;
  height: 150px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgb(238, 238, 238);
  background-image: url("../public/images/bet_show.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.how_to_play_image1 {
  width: 101%;
  height: 150px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgb(238, 238, 238);
  background-image: url("../public/images/character.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.how_to_play_image2 {
  width: 101%;
  height: 150px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgb(238, 238, 238);
  background-image: url("../public/images/cashout.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.how_to_play_details p {
  padding: 10px 20px;
  font-weight: 700;
  font-size: 14px;
  color: white;
}
.how_to_play_risk p {
  font-size: 14px;
  font-weight: 700;
  margin: 15px 10px;
}
.how_to_play_rules {
  padding: 16px 10px;
}
.how_to_play_rules h2 {
  font-size: 25px;
  font-weight: 700;
  margin: 10px 10px;
}
ul.how_to_play_rules_li {
  padding-left: 24px;
}
ul.how_to_play_rules_li li {
  font-size: 14px;
  font-weight: 600;
  padding: 6px 0px;
  color: rgba(223, 229, 242, 0.7);
}
/* payment history */
.history_detail .all_history {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  position: relative;
  z-index: 100;
}
.history_detail .history_list {
  background: #1c263d;
  border-radius: 15px;
}
.deposit_history.all_history::after {
  content: "";
  background: url("../public/images/model_right_top.png") no-repeat;
  background-size: auto;
  background-size: contain;
  position: absolute;
  top: -33px;
  right: -38px;
  width: 96px;
  height: 227px;
  z-index: -1;
}
.paymentHistory {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  margin-top: 80px;
}
.history_detail .history_list_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px;
  padding: 20px;
}
.history_list_detail h6 {
  color: #ffffff;
}
.history_list_detail .upi_id_text {
  margin-bottom: 10px !important;
}
.widthdraw_detail {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #1b1c1d;
  border-radius: 15px 15px 0px 0px;
}
.widthdraw_title h1 {
  color: #1bd270;
  margin-bottom: 10px;
}
.form-control.floating::-webkit-inner-spin-button,
.form-control.floating::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* no payment history */
.no-deposit-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 40px;
}
.no-deposit-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 15px;
}
.no-deposit-content p {
  font-size: 20px;
}
.no-deposit-content .deposit-btn {
  margin: 0;
  margin-top: 30px;
}
/* personal information */
.deposit-detail.personal_info_detail {
  display: flex;
  flex-direction: row;
}
.personal_info_detail .calendar_vector {
  position: absolute;
  right: 18px;
  z-index: 0;
}
.dateOfBirth .form-control.floating {
  position: relative;
  z-index: 1;
}
.dateOfBirth label {
  z-index: 1;
}
.personal_info_detail .form-select option {
  background: #1b1c1d;
}
.modal-dialog .personal_info_detail .col-12 .form-control {
  width: 96% !important;
  margin-left: auto;
}
.modal-dialog .personal_info_detail .col-4 .form-control {
  width: 92% !important;
  margin-left: auto;
}
.modal-dialog .personal_info_detail .col-6 .form-control {
  width: 90% !important;
  margin-left: auto;
}
/* ChangeLanguage */
#exampleModal4 .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}
.language_lists form {
  display: flex;
  flex-direction: column;
}
.change_langauge_content {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}
.waiting_next_round {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #ffd700 !important;
  font-family: none !important;
}
.language_lists form .language_list {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  border-top: 1.5px solid #2a3763;
}
.language_lists form .language_list img {
  margin-left: auto;
  position: relative;
  right: 15px;
}
.language_lists form .language_list [type="radio"]:checked,
.language_lists form .language_list [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.language_lists form .language_list [type="radio"]:checked + label,
.language_lists form .language_list [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 56px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #fff;
}
.language_lists form .language_list [type="radio"]:checked + label:after,
.language_lists form .language_list [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 24px;
  height: 24px;
  background: url("../public/images/check_yes.png") no-repeat;
  position: absolute;
  left: 19px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.language_lists form .language_list [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.language_lists form .language_list [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/* Support */
.support_section {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}
.refer_section {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.support_section .support_img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.support_section .support-content {
  background: #1c263d;
  border-radius: 20px;
  padding: 30px 20px;
}
.suppor_mail {
  display: flex;
  column-gap: 15px;
  align-items: center;
  padding: 6px;
  border: 2px solid #2a3763;
  border-radius: 54px;
}
.support_section h5 {
  font-size: 18px;
  margin-bottom: 8px;
}
.modal-dialog .support-content .mail_img {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222d47;
  position: relative;
}
.follow_us {
  position: relative;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.follow_us .follow_line {
  position: absolute;
  width: 100%;
  top: 50px;
}
.follow_us h5 {
  font-size: 18px;
  background-color: #1c263d;
  margin: 0;
}
.support_btns {
  display: flex;
  justify-content: center;
  column-gap: 24px;
  flex-wrap: wrap;
  row-gap: 24px;
}
.support_btns .main-btn {
  border-radius: 30px;
  display: flex;
  column-gap: 30px;
  align-items: center;
  padding: 3px;
  max-width: 250px;
}
.support_btns .main-btn.Telegram_btn {
  background: #29a9eb;
}
.support_btns .main-btn.facebook_btn {
  background: linear-gradient(0deg, #1877f2, #1877f2), #29a9eb;
}
.support_btns .main-btn.instagram_btn {
  background-color: #fd00b4;
}
.support_btns .main-btn.google_btn {
  background: #ffffff;
  color: #270841;
}
.support_btns .main-btn h5 {
  margin: 0;
  margin-right: 30px;
}
.avbar-right {
  padding: 10px 0px;
}
/* TermCondition */
.termCondition_section .Condtionleft_top {
  display: flex;
  column-gap: 18px;
  align-items: self-start;
}
.conditionImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.support-input {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 6px;
  border-bottom: 1px solid #2a3763;
}
.support-input-btn {
  padding: 6px 8px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  );
  border: 1px solid #ffd700;
  color: black;
}
.condition_right_First {
  line-height: 28px;
  margin-bottom: 30px;
}
.condition_left {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.condition_left_First {
  line-height: 34px;
}
.termCondition_section ul li {
  color: #818ea5;
}
.navbar-color {
  background-color: #1b1c1d;
}
.main-btn {
  border: 0;
  background-color: transparent;
  color: #ffffff;
}
.login-btn {
  outline: 2px solid #222d47;
  outline-offset: 4px;
  padding: 2px 16px 2px 4px;
  background: #222d47;
  border-radius: 19.5px;
  margin-left: 15px;
}
.back_button_content_a {
  text-decoration: none;
}
.main-btn.user-btn {
  justify-content: space-between;
  align-items: center;
  column-gap: 14px;
  padding: 10px;
  height: 43px;
  display: flex;
  margin: 0;
}
.main-btn.user-btn .login-btn-img {
  margin: 0;
}
.main-btn.deposit-btn {
  outline: 2px solid #0da252;
  background: linear-gradient(180deg, #1bd270 0%, #0da252 47.92%, #0a9349 100%);
}
.main-btn.deposit-btn:focus:not(:focus-visible) {
  outline: 2px solid #0da252;
  outline-offset: 4px;
}
.main-btn.withdraw-btn {
  outline: 2px solid #ffd700;
  color: black;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  ),
    linear-gradient(180deg, #1bd270 0%, #0da252 47.92%, #0a9349 100%);
}
.main-btn.withdraw-btn:focus:not(:focus-visible) {
  outline: 2px solid #ffd700;
  outline-offset: 4px;
}
.middle_line {
  height: 100%;
  font-size: 42px;
  color: #222d47;
}
.login-btn:focus:not(:focus-visible) {
  outline: 2px solid #222d47;
  outline-offset: 4px;
}
.login-btn .login-btn-img {
  margin-right: 13px;
}
.border {
  height: 500px;
}
.navbar-right {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
}

/* .user-detail-popup */
.user-detail-popup {
  position: absolute;
  top: 75px;
  right: 70px;
  width: 300px;
  z-index: 1;
}
/* .user-detail-content{
  border-radius: 18px 18px 0px 0px;
} */
.user_title_content {
  position: relative;
}
.user_title_content button {
  min-width: 120px;
}
.user-detail-content .user_title {
  background: #1b1c1d;
  padding: 12px;
  border-radius: 18px 18px 0px 0px;
}

tr.winBat td:nth-child(3) {
  background: grey !important;
  color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 62px;
  min-width: 62px;
  max-height: 31px;
  min-height: 31px;
  margin: 12px 8px;
  padding: 0 !important;
}
tr.winBat td:nth-child(3) p {
  color: black;
}
tr.winBat td:nth-child(4) span {
  font-weight: 600;
  font-size: 14px;
  color: black;
}
td .win_amount {
  color: #fbdc4b;
}
tr.winBat {
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  ),
    #222d47;
}
.winBat td {
  color: black;
  font-weight: 600;
  vertical-align: middle;
}
tr.winBat td:nth-child(2) {
  font-weight: 600;
  font-size: 14px;
  color: black;
}
tr.winBat td:last-child p {
  font-weight: 600;
  font-size: 14px;
  color: #fbdc4b;
}
td {
  vertical-align: middle;
}
.user_list p {
  cursor: pointer;
}
.user-detail-content .user_list {
  background-color: #222d47;
  padding-bottom: 12px;
  border-radius: 0 0 20px 20px;
}
.user-detail-content .user_list_item:not(:last-child) {
  border-bottom: 1px solid #2a3763;
}
.user-detail-content .user_list_item {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 12px;
}
.accordion-item {
  width: 100%;
  background: transparent;
  border: 0;
}
.accordion-button {
  background: transparent;
  display: flex;
  column-gap: 18px;
  padding: 0;
}
.accordion-body {
  padding: 0;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  padding-bottom: 8px;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 22px;
  height: 17px;
  margin-left: auto;
  content: "";
  background-image: url("../public/images/collapes_vector.svg");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../public/images/collapes_vector.svg");
  transform: rotate(-180deg);
}
.user-detail-popup {
  width: 360px;
  position: absolute;
  top: 80px;
  right: 30px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10%);
  transition: 0.5s;
  background: #222d47;
  border: 2px solid #2a3763;
  box-shadow: 0px 3px 20px #640977;
  border-radius: 20px;
}
.user-detail-popup::after {
  content: "";
  background-image: url("../public/images/pop_up_vector.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -19px;
  right: 30px;
  width: 32px;
  height: 19px;
}
.user-detail-popup.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
}
.user_list_item .lms_checkbox_1 {
  display: inline-block;
  height: 18px;
  position: relative;
  width: 36px;
  margin: 0px;
  margin-left: auto;
}
/* Container */
#Container {
  padding: 20px;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  overflow: auto;
  max-height: 876px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
#Container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.g-3 {
  --bs-gutter-x: 14px !important;
  --bs-gutter-y: 14px !important;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 20px !important;
  --bs-gutter-y: 20px !important;
}
.batting-btns-bar .login-btn {
  margin: 0px;
}
.batting-btns-bar .main-btn.login-btn {
  box-shadow: 0;
}
.batting-btns-bar .login-btn-img {
  margin-right: 8px;
}
.dropdown-vector {
  margin-left: 8px;
}
.battings-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  margin-bottom: 20px;
}
#battingsbtns {
  display: flex;
}
#battingsbtns.active {
  display: block;
  position: relative;
}
#battingsbtns.active .battling-button {
  margin: 3px;
}
.batting-btns-list_active {
  position: absolute;
  z-index: 100;
  background: #1c263d;
  width: 100%;
  padding: 13px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
}
#battingTitle {
  display: none;
  background: #222d47;
  border-radius: 9px 9px 0px 0px;
  justify-content: space-between;
  height: 67px;
  align-items: center;
  padding: 0 25px;
}
#histroy_batting_close .login-btn {
  outline: 1.5px solid #ffd700;
  outline-offset: 1.5px;
  color: black;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  );
  border-radius: 19.5px;
  box-shadow: none;
}
#battingTitle.active {
  display: flex;
  margin: 0;
}
/* #BattingLists{
  position: relative;
}
#BattingLists.BattingLists_add{
  display: block;
position: absolute;
z-index: 100;
width: 100%;
background: #1C263D;
border: 2px solid #2A3763;
border-radius: 11px;
} */
.batting-btns-list {
  display: flex;
  column-gap: 4px;
  width: calc(100% - 120px);
  overflow-x: hidden;
  position: relative;
}
.battling-button {
  padding: 6px 18px;
  border-radius: 10.5px;
}
.battling-button.twodown {
  color: #ffffff;
  background-color: #596adc;
}
.battling-button.twoup {
  background-color: #fbdc4b;
  color: #090f1e;
  font-size: 12px;
  font-weight: 600;
}
.batting-btns-bar {
  display: flex;
}
.batting-btns-bar_close {
  display: none;
}
.batting-btns-bar .login-btn {
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  height: 43px;
  padding: 2px 8px 2px 4px;
  display: flex;
  box-shadow: -17px 0px 4px #090f1e91;
  position: relative;
}
/* Batting_section */
#Batting_section {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 177px);
  background: #1b1c1d;
  border: 1.5px solid #2a3763;
  border-radius: 18px;
  justify-content: space-between;
  overflow: hidden;
  min-height: 518px;
}
#Container h1 {
  font-family: "Trim Web";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
}
.loading-section {
  position: relative;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 360px;
}
.loadimg-images {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.loadimg-images .loadingOnProgress_ {
  width: 100%;
  height: 100%;
}
.loadimg-images .loadingRocketSuccessfull_ {
  width: 37px;
  height: 94px;
}
.loading.loadingOnProgress {
  animation: Rotate infinite linear;
  animation-delay: 0s;
  animation-timing-function: 5s;
  animation-duration: 3s;
}
@keyframes Rotate {
  to {
    transform: rotate(360deg);
  }
}
.loading-reaction .loading-rocket {
  position: absolute;
}
.loading-rocket.loadingRocketSuccessfull {
  animation: Rocket 1s ease-out;
  animation-delay: 5s;
}
@keyframes Rocket {
  to {
    transform: translateY(0px);
  }
  from {
    transform: translateY(-200px);
  }
}
.progress-wrapper {
  width: 456px;
  height: 14px;
  background-color: #222d47;
  outline: 1px solid #2a3763;
  position: relative;
  border-radius: 7px;
  margin: 7px;
}
.progress1:after {
  content: "";
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  ),
    linear-gradient(0deg, #110f0f, #110f0f),
    linear-gradient(0deg, #684040, #684040), #d9d9d9;
  position: absolute;
  top: 0;
  left: 0px;
  height: 10px;
  border-radius: 7px;
  animation: color 10s linear infinite;
}
.progress1::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #999;
  bottom: 20px;
  left: -10px;
  animation: bounce 3.7s linear infinite;
}
@keyframes bounce {
  0% {
    bottom: 0px;
    left: 10%;
    opacity: 0;
    background: #8016c0;
  }
  20% {
    bottom: 20px;
    left: 20%;
    opacity: 1;
    background: #ffd700;
  }
  30% {
    bottom: 0px;
    left: 30%;
  }
  40% {
    bottom: 20px;
    left: 40%;
  }
  50% {
    bottom: 0px;
    left: 50%;
    background: #6716b0;
  }
  60% {
    bottom: 20px;
    left: 60%;
  }
  70% {
    bottom: 0px;
    left: 70%;
    background: #a015d7;
  }
  80% {
    bottom: 20px;
    left: 80%;
  }
  90% {
    bottom: 0px;
    left: 90%;
    opacity: 1;
  }
  100% {
    bottom: 20px;
    left: 100%;
    opacity: 0;
  }
}

@keyframes color {
  0% {
    width: 0%;
    background: #8016c0;
  }
  10% {
    width: 10%;
    background: #ffd700;
  }
  20% {
    width: 20%;
    background: #6716b0;
  }
  30% {
    width: 30%;
    background: #a015d7;
  }
  40% {
    width: 40%;
    background: #8016c0;
  }
  50% {
    width: 50%;
    background: #8016c0;
  }
  60% {
    width: 60%;
    background: #6716b0;
  }
  70% {
    width: 70%;
    background: #ffd700;
  }
  80% {
    width: 80%;
    background: #6716b0;
  }
  90% {
    width: 90%;
    background: #8016c0;
  }
  100% {
    width: 100%;
    background: #6716b0;
  }
}
.batting_list_users table tbody tr {
  text-align: center;
}
.batting_list_users table thead tr {
  text-align: center;
}
.user_dp_camera {
  position: relative;
}
.user_dp_camera_img {
  height: 66px;
  width: 66px;
  position: relative;
  background-color: #2a3763;
  border: 1px solid #1b1c1d;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  object-fit: cover;
}
.upload__image-wrapper {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.upload__image-wrapper:hover {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
.deposit {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.deposit:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.user_title_content {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.user_title_content:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.active_bat_tab {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.active_bat_tab:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.deposit_options.active {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.deposit_options.active:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.next-page {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.next-page:hover {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}
.active_payment_tab {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.active_payment_tab:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.main-btn.login-btn {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.main-btn.login-btn:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}
.main-btn.google_btn {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.main-btn.google_btn:hover {
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}
.main-btn.facebook_btn {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.main-btn.facebook_btn:hover {
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}
.close_page a img {
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}
.close_page a img:hover {
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}
#loadind_slider_input {
  display: none;
}
.text-yellow a:hover {
  color: #ffd700 !important;
}
i.fa.fa-camera.user_dp_camera_upload {
  position: absolute;
  bottom: 5px;
  right: 0px;
  background-color: #2a3763;
  border: 1px solid #1b1c1d;
  padding: 5px 6px;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
}

/* batting-detail */
.batting-detail {
  width: 96%;
  margin-bottom: 15px;
}
.right_bg {
  background: #090f1e;
  border-radius: 15px;
  width: 100%;
  padding: 11px;
  margin-bottom: 0px;
}
.bet_content-right {
  border-radius: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.batting_count-detail {
  width: 59%;
}
.bet_content-btn {
  width: 39%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bet_content-btn .bet-btn {
  width: 93%;
  height: 90%;
  outline: 2px solid #ffd700;
  outline-offset: 4px;
  padding: 2px 16px 2px 4px;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  );
  border-radius: 20px;
  color: black;
  font-size: 14px;
}
.loading_proress {
  margin-top: 20px;
}
.bet-btn.bet_cancel {
  outline: 2px solid #d12913;
  background: #d12913;
}
.bet_content-btn .bet-btn:not(:focus-visible) {
  outline: 2px solid #ffd700;
  outline-offset: 4px;
}
.bet-btn.bet_cancel:not(:focus-visible) {
  outline: 2px solid #d12913;
  outline-offset: 4px;
}
.play_batting {
  width: 100%;
}
.batting_content_detail {
  background-color: #2a3763;
  padding: 0 9px;
  border-radius: 10px;
  margin-bottom: 6px;
}
/* input checkbox */
.lms_checkbox_1 {
  display: inline-block;
  height: 18px;
  position: relative;
  width: 36px;
  margin: 2px 0 0 0;
}
.lms_checkbox_1 input {
  display: none !important;
}
.lms_checkbox_1 input:checked + .slider-check {
  color: #fff;
  content: "oui";
}
.lms_checkbox_1 .slider-check {
  background-color: #1b1c1d;
  border-radius: 34px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.lms_checkbox_1 .slider-check.round::before {
  background-color: #2a3763;
  border-radius: 50%;
  top: 2px;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
}
.lms_checkbox_1 input:checked + .slider-check::before {
  transform: translateX(17px);
  background: linear-gradient(180deg, #1bd270 0%, #0da252 47.92%, #0a9349 100%);
}
.play_batting_count {
  display: flex;
  column-gap: 7px;
  align-items: center;
}
.play_batting_inr {
  display: flex;
  column-gap: 5px;
  justify-content: space-between;
  background: #1b1c1d;
  border-radius: 20px;
  align-items: center;
  padding: 4px 6px;
}
/* .play_batting_inr input {
  width: auto;
  max-width: 55px;
  background: transparent;
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fbdc4b !important;
  width: 52px;
  text-align: center;
}
.play_batting_inr input:focus-visible {
  box-shadow: none;
  outline: navajowhite;
} */
.inr-plus,
.inr-minus {
  display: flex;
  cursor: pointer;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
  height: 30px;
  align-items: center;
  width: 30px;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  );
  border-radius: 50%;
  color: black;
}
.play-batting-btns.d-flex.justify-content-between {
  column-gap: 6px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
  overflow: auto;
}
.play-batting-btns.d-flex.justify-content-between::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}
.play-batting-btns.d-flex.justify-content-between::-moz-scrollbar {
  display: none;
  scrollbar-width: none;
}
.play_batting-btn {
  background: #1b1c1d;
  border-radius: 4px;
  padding: 6px 17px;
}
.tag-bet-input-price div {
  font-weight: 500;
  line-height: 19px;
  color: #fbdc4b;
  text-align: center;
}
.amount-data-show {
  text-align: center;
}
.amount-data-show-btn {
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  );
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0px 5px;
}
.amount-data-show-amount {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
/* @media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .batting-detail {
    width: 96%;
    margin: 15px;
  }
  .batting_count-detail {
    width: 69%;
  }
  .bet_content-btn {
    width: 29%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  #Container {
    padding: 20px 5px;
  }
  .bets_content {
    padding: 10px 5px;
    margin-bottom: 15px;
  }
  .top_bets_content {
    margin-bottom: 16px;
  }
} */
/* #"BetsSection */
.rightSide {
  margin-top: 8px !important;
}
.bets-tabs {
  background: #222d47;
  border-radius: 26.5px;
  padding: 7px;
  margin-bottom: 11px;
}
.payment-tabs {
  background: #1b1c1d;
  border-radius: 26.5px;
  padding: 0 7px;
  max-width: 600px;
  margin: auto;
  width: 100%;
}
.bets-tabs ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  margin: 0 !important;
  column-gap: 10px;
  align-items: center;
  height: 47px;
}
.payment-tabs ul {
  list-style-type: none;
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
  column-gap: 10px;
  align-items: center;
  height: 60px;
}
.bets-tabs ul li,
.payment-tabs ul li {
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.bets-tabs ul li a,
.payment-tabs ul li a {
  text-decoration: none;
}
.bets-tabs .active_bat_tab,
.payment-tabs .active_payment_tab {
  outline: 1.5px solid #ffd700;
  outline-offset: 1.5px;
  padding: 10px 16px 10px 4px;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  );
  border-radius: 19.5px;
  color: black;
}
.bts_section {
  background: #1b1c1d;
  border: 1.5px solid #2a3763;
  border-radius: 18px;
  overflow-y: scroll;
  scrollbar-width: none;
  height: calc(100vh - 176px);
  max-height: 876px;
  min-height: 518px;
}

#Battingtables {
  min-width: 360px;
  overflow: scroll;
  scrollbar-width: none;
}
.bts_section::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}
.bts_section::-moz-scrollbar {
  display: none;
  scrollbar-width: none;
}
#Battingtables::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}
#Battingtables::-moz-scrollbar {
  display: none;
  scrollbar-width: none;
}
.bts_section::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
  scrollbar-width: 0;
}
.bts_section::-moz-scrollbar {
  display: none;
  scrollbar-width: none;
  scrollbar-width: 0;
}
.bets-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}
.bets-title h5 {
  font-weight: 9500;
  font-size: 22px;
  line-height: 26px;
  color: #fcde52;
}
.bets-title .batting-btns-bar .login-btn {
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  height: 43px;
  padding: 2px 8px 2px 4px;
  display: flex;
  position: relative;
  box-shadow: none;
}
/* batting tables */
#Battingtables .table {
  color: #fff !important;
}
#Battingtables .table thead tr {
  background-color: #222d47;
  color: #b4b4b5;
}
#Battingtables .table tbody tr {
  background-color: transparent;
  color: #8999b0;
}
#Battingtables .table > :not(caption) > * > * {
  padding: 12px 8px;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-color: #2a3763;
}
/* video section */
.batiing-video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 15px;
  /* height: calc(100vh - 390px); */
}
.video_section {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}
.video_section img {
  min-height: 437px;
}
.video_main {
  max-width: 100%;
  max-height: 100%;
}
.batTimeSection {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.plane {
  position: absolute;
  left: 0%;
  bottom: 5%;
  transform: rotate(-35deg);
  margin: -5% -5% 0 0;
}
.plane-img {
  max-width: 200px;
  max-height: 200px;
  transform: rotate(90deg);
}

@keyframes cloud {
  0% {
    left: 0%;
    bottom: -5%;
    transform: none;
    transform: rotate(-35deg);
  }

  /* 1% {
    left: 12%;
    bottom: -5%;
    transform: none;
  }
  1.2% {
    left: 13%;
    bottom: -4%;
    transform: rotate(-35deg);
  } */
  5% {
    left: 75%;
    bottom: 55%;
    transform: rotate(-35deg);
  }
  100% {
    left: 75%;
    bottom: 55%;
    transform: rotate(-35deg);
  }
}
tr.winBat .user_title .userImg {
  border: 0.5px solid #fff;
}
tr.winBat .user_title span {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}

.top_batting_section {
  padding: 30px;
}
.user_title .userImg {
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 10px;
  text-transform: uppercase;
}
.top_bets_content .user_title .userImg .bet_user {
  width: 25px;
  height: 25px;
  max-width: 23px;
  max-height: 23px;
}
.user_title {
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 16px;
}
.top_bets_content .user_title {
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 18px;
}
.Collect h4 span {
  font-size: 20px;
  font-weight: 700;
}
.top_bets_content .user_title .userImg {
  width: 36px;
  height: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
  border: 0.5px solid #2a3763;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bets_content {
  background: #222d47;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}
.top_bets_content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}
.bottom_bets_content {
  display: flex;
  justify-content: space-between;
  column-gap: 14px;
}
.top_bets_content .Collect {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  color: #eac954;
  position: relative;
  top: -5px;
  text-align: center;
  font-weight: 900;
}
.bottom_bets_content .bet_detail {
  background: #1b1c1d;
  border-radius: 11px;
  width: 48%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  height: 45px;
  align-items: center;
}
.bet_amount {
  color: #fff;
}
.main-btn.calendar-btn {
  justify-content: space-between;
  align-items: center;
  column-gap: 14px;
  padding: 3px;
  height: 43px;
  display: flex;
  margin: 0;
  background: #1b1c1d;
  border-radius: 19.5px;
}
.main-btn.calendar-btn span {
  margin-right: 3px;
}
.topbatting_section .user_title {
  display: flex;
  align-items: flex-start;
  column-gap: 6px;
  width: 150px;
}
.bets-tabs .active_bat_tab,
.payment-tabs .active_payment_tab {
  outline: 1.5px solid #ffd700;
  outline-offset: 1.5px;
  padding: 10px 16px 10px 4px;
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  );
  border-radius: 19.5px;
  color: black;
}

/* login pages */

#Login {
  background-color: #090f1e;
}
.container-fluid,
.login_container,
.login_container .row {
  height: 100%;
}
.login_content {
  display: flex;
  flex-direction: column;
  column-gap: 60px;
  padding: 20px;
}
.login_right {
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  row-gap: 60px;
  background: #1b1c1d;
  height: 100%;
}
.login_right::before {
  content: "";
  background: url(../public/images/login_right_bottom_vector.png) no-repeat;
  position: absolute;
  width: 26.8px;
  height: 217.2px;
  bottom: 0;
  left: 29px;
}
.login_right::after {
  content: "";
  background: url(../public/images/login_right_top_vector.png) no-repeat;
  position: absolute;
  width: 217.2px;
  height: 26.8px;
  top: 40px;
  right: 0;
}
.login_right h5 {
  font-size: 20px;
  line-height: 24px;
  color: #ffd700;
}
.login_right_img_section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_left {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 100%;
  position: relative;
  /* height: 887px; */
}
#AddressInfo .login_left {
  height: 666px;
}
.login_left .close_page {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 0px;
  cursor: pointer;
  z-index: 100;
}
.login_bg_IMG {
  position: absolute;
  width: 100%;
  height: 100%;
}
.login_informention {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login_content {
  display: flex;
  flex-direction: column;
  column-gap: 60px;
  padding: 29px;
  height: 100%;
}

.login_content .form-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  position: relative;
  top: -30px;
  top: -11px;
  row-gap: 20px;
}
.login_information_img {
  position: absolute;
  left: 0%;
  top: 0;
  z-index: 0;
}
.login_information_img_top {
  min-height: 375px;
}
.form-group.floating {
  display: flex;
  column-gap: 10px;
  border: 2px solid #2a3763;
  border-radius: 52.5px;
  position: relative;
  padding: 3px;
  align-items: center;
}
.form-group.floating > label {
  left: 81px;
  color: #6c7990;
  position: absolute;
  background-color: transparent;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.1s;
  pointer-events: none;
  font-weight: 500 !important;
  transform-origin: bottom left;
  background: #1b1c1d !important;
}
.form-control::placeholder {
  color: #6c7990;
  opacity: 1;
}
.form-control:focus {
  color: #6c7990;
  outline: 0 !important;
  box-shadow: none !important;
}
.form-control {
  background: transparent !important;
  border: 0 !important;
  color: #6c7990;
  width: 78% !important;
}
.form-control.floating:focus ~ label {
  transform: translateY(-40px) scale(0.8);
  opacity: 1;
  color: #6c7990;
  box-shadow: none;
}
.form-control:focus {
  border-color: 0;
  outline: 0;
  box-shadow: none;
}
.form-control.floating:valid ~ label {
  transform-origin: bottom left;
  transform: translateY(-40px) scale(0.8);
  opacity: 1;
  box-shadow: none;
}
.login_informention .mail_img {
  min-width: 66px;
  min-height: 66px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222d47;
  position: relative;
}
.mail_img .uploadimageshow,
.mail_img .uploadimageshowPAYTM {
  position: absolute;
  bottom: 52px;
  left: -111px;
  display: none;
  z-index: 100;
}
.mail_img .uploadimageshow #imgshow {
  width: 278px;
  height: 494.22px;
}
.mail_img .uploadimageshowPAYTM #imgshowPAYTM {
  width: 278px;
  height: 494.22px;
}
.uploadimageshow .uploadimg_vector,
.uploadimageshowPAYTM .uploadimg_vector {
  position: relative;
  left: 42%;
}
.uploadimageshow.active,
.uploadimageshowPAYTM.active {
  display: block;
}
.forgot_password {
  position: absolute;
  right: 20px;
  bottom: -30px;
}
.button-section {
  position: relative;
  right: 5px;
  bottom: -77px;
}
.login_option .support_btns .main-btn {
  border-radius: 30px;
  display: flex;
  column-gap: 30px;
  align-items: center;
  padding: 3px 6px;
  width: 48%;
  max-width: 330px;
}
.next_button {
  display: flex;
  justify-content: end;
}
.login_option_content {
  display: flex;
  flex-direction: column;
  row-gap: 38px;
  position: relative;
  padding: 55px 47px 5px;
  height: 100%;
  max-height: 312px;
  width: 100%;
}
.login_option .login_information_img {
  height: 100%;
}
.login_option .support_btns {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  row-gap: 24px;
}
.login_ng_IMG {
  max-height: 844px;
}
.login_content2 {
  max-height: 442px;
}
.login_option2 {
  max-height: 300px;
  position: relative;
  top: -43px;
}
.login_option .support_btns .main-btn h5 {
  margin: 0;
  margin-right: 0px;
  margin-left: 0px;
  margin-right: auto;
  margin-left: auto;
}
#Login,
#PersonalInfo,
#AddressInfo,
#LoginRegister,
#ForgetPassword,
#VerifyAccount,
#Changepassword {
  background-color: #090f1e;
  height: calc(100vh - 78px);
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

#Changepassword::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
#LoginRegister::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
#VerifyAccount::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
#AddressInfo::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
#ForgetPassword::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
#PersonalInfo::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
#Login::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.Toastify__toast-theme--light {
  background-color: #1b1c1d !important;
}
.Toastify__toast-body div {
  color: #fff !important;
}
.Toastify__close-button--light {
  color: #fff !important;
  opacity: 0.5 !important;
}
.login_right_img_section {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* register */
.Login_line {
  height: 20px;
  position: relative;
  width: 100%;
  border-bottom: 1.5px solid #2a3763;
  margin: 25px 0 50px;
}
.Login_line .line_dots {
  position: absolute;
  top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}
.Login_line .line_dots .line_dot {
  width: 15px;
  height: 15px;
  background: #1b1c1d;
  border: 2px solid #2a3763;
  border-radius: 50%;
  position: relative;
}
.Login_line .line_dots .line_dot.complate {
  background: #1b1c1d;
  border: 2px solid #ffd700;
}
.Login_line .line_dots .line_dot.complate::before {
  content: "";
  position: absolute;
  right: 13px;
  width: 200px;
  height: 1.5px;
  top: 50%;
  background: #ffd700;
  z-index: 0;
}
.status.accept1 {
  color: green;
  background-color: rgba(0, 128, 0, 0.151);
  padding: 6px 10px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
}
.status.pending1 {
  color: goldenrod;
  background-color: rgba(189, 189, 3, 0.103);
  padding: 6px 10px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
}
.status.reject1 {
  color: rgb(209, 0, 0);
  background-color: rgba(176, 41, 31, 0.151);
  padding: 6px 10px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
}
.Login_line .line_dots .line_dot.complate::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: -2px;
  right: -2px;
  background: #1b1c1d;
  border: 2px solid #ffd700;
  border-radius: 50%;
  z-index: 100;
}
.Register .login_content .form-section {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  position: relative;
  row-gap: 20px;
  top: 0;
}
.Register .form-group.floating {
  height: 76px;
}
.Register .form-control {
  width: 100% !important;
  margin-left: 43px;
}
.Register .form-group.floating > label {
  left: 57px;
  background: #1b1c1d !important;
}
.Register .login_option2 {
  max-height: 300px;
  position: relative;
  top: -20px;
}
.Register .login_content2 {
  max-height: none;
}
.Register .login_left {
  height: 100%;
  max-width: none;
}
.register_right_bottom {
  position: absolute;
  bottom: 0;
  left: 15px;
}
.register_right_top {
  position: absolute;
  top: 50px;
  right: 15px;
}

/* personal information */
.Register.LoginperonalInformation .login_content .form-section {
  display: flex;
  flex-direction: row;
  height: 58%;
  justify-content: space-around;
  position: relative;
  row-gap: none;
  top: 0;
}
.Register.LoginperonalInformation .button-section {
  position: relative;
  right: 5px;
  bottom: -74px;
}
#AddressInfo .Register.LoginperonalInformation .button-section {
  position: relative;
  right: 5px;
  bottom: -22px;
}
.Register.LoginperonalInformation .Login_line .line_dots {
  position: absolute;
  top: 11px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  overflow: hidden;
}
.login_informention .calendar_vector {
  position: absolute;
  right: 18px;
  z-index: 0;
}
.back_button_content {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.Address .Login_line {
  height: 20px;
  position: relative;
  width: 100%;
  border-bottom: 1.5px solid #2a3763;
  margin: 25px 0 30px;
}
#hide {
  display: none;
}
#AddressInfo .register_right_bottom,
#PersonalInfo .register_right_bottom {
  position: absolute;
  bottom: 200px;
  left: 15px;
}
/* VerifyAccount */
.verify_number_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 30px;
  position: relative;
  top: 70px;
}
.number_inputs {
  display: flex;
  justify-content: center;
  position: relative;
}
.number_inputs .number_input {
  border: 2px solid #2a3763;
  border-radius: 15px;
  padding: 31px;
  max-width: 90px;
  max-height: 90px;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
}
.number_inputs .number_input::-ms-value {
  color: white;
}
a.wrong_number {
  font-size: 20px;
  line-height: 45px;
  color: #eac954 !important;
}
a.resend_code {
  font-size: 16px;
  line-height: 45px;
  color: #eac954 !important;
  text-decoration: none;
}
#VerifyAccount .button-section {
  position: relative;
  right: 5px;
  bottom: -150px;
}
#VerifyAccount .register_right_bottom {
  position: absolute;
  bottom: 180px;
  left: 15px;
}
/* ForgetPassword */
#ForgetPassword .login_informention {
  max-height: 645px;
}
h5.Forget_text {
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  width: 90%;
  margin-bottom: 50px;
}
#ForgetPassword .button-section {
  position: relative;
  right: 5px;
  bottom: 16px;
}
#ForgetPassword .register_right_bottom {
  position: absolute;
  bottom: 379px;
  left: 15px;
}
#showPassword {
  position: relative;
  right: 15px;
}
.bet_checkout {
  width: 93%;
  height: 90%;
  outline: 2px solid #0da252;
  outline-offset: 4px;
  padding: 2px 16px 2px 4px;
  background: green;
  border-radius: 20px;
  color: #ffffff;
  font-size: 18px !important;
  font-weight: 700;
}
.cross-icon-change {
  position: absolute;
  right: 8px;
  height: 40px;
  width: 40px;
}
.cross-icon-change-p {
  font-size: 18px;
  padding-left: 25px;
}
.count-position {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  animation-timing-function: unset;
  animation-delay: unset;
  animation-iteration-count: unset;
  animation-direction: unset;
  animation-fill-mode: unset;
  animation-play-state: unset;
  animation-name: unset;
  animation-duration: 0.7s;
  /* animation: zoominoutsinglefeatured 1s infinite; */
}
.count-position h2 {
  font-weight: 600;
  font-size: 26px;
}
.count-position h4 {
  color: #fbdc4b;
  font-size: 26px;
  font-weight: 600;
}
.Toastify__progress-bar--success {
  background: linear-gradient(
    180deg,
    #ffd700 0%,     /* Gold */
    #ffec80 100%    /* Lighter Gold */
  ) !important;
}
.Toastify__toast-icon svg {
  fill: #ffd700 !important;
}
@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.video_section_animate {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.videosection_img {
  min-height: 437px;
  height: 100%;
  width: 100%;
}
.video_section_bg_img {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../public/images/animation_bg.png") 0% 0% / contain repeat-x;
  animation: 37030s linear 0s infinite normal none running gKBsdr;
}
.animation_cloud_bg {
  position: absolute;
  width: 100%;
  height: 157px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
  background-position: 0% top;
  background-repeat: repeat-x;
  background-attachment: scroll;
  background-image: url("../public/images/animation_clouds.svg");
  background-origin: padding-box;
  background-clip: border-box;
  animation: 37037s linear 0s infinite normal none running gKBsdr;
  opacity: 0.5;
  transition: background-position-y 0.64s ease 0s, opacity 0.64s ease 0s;
  background-size: initial;
}
@keyframes gKBsdr {
  100% {
    background-position-x: -1000000px;
  }
}
.animation_bottom_lines {
  position: absolute;
  bottom: 0px;
  left: 20px;
  width: 100%;
  height: 20px;
  background-image: url("../public/images/animation_bottom_lines.svg");
  animation: 3s linear 0s infinite normal none running eNKMhd;
  border-top: 1px solid rgb(38, 28, 74);
  background-repeat: repeat-x;
}
@keyframes eNKMhd {
  100% {
    background-position-x: -164px;
  }
}
.animation_left_lines {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 20px;
  height: 100%;
  background-image: url("../public/images/animation_left_lines.svg");
  animation: 3s linear 0s infinite normal none running gySJRx;
  border-right: 1px solid rgb(38, 28, 74);
  background-repeat: repeat-y;
}
@keyframes gySJRx {
  100% {
    background-position-y: 164px;
  }
}
.animation_animate {
  position: absolute;
  bottom: 21px;
  left: 21px;
  width: calc(100% - 21px);
  height: calc(100% - 21px);
}
.video_section img {
  min-height: auto;
}
.fly {
  position: absolute;
  width: 200px;
  height: 200px;
  opacity: 1;
  transition: all 1.5s ease-in-out;
}
.fly.complate {
  transition: all 0.5s ease-in-out;
}

.tag-bet-input {
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  overflow: hidden;
}
.tag-bet-input-div {
  border: none;
  outline: none;
  width: calc(100% + 25px);
  min-width: 0px;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0px;
  font-weight: 500;
  line-height: 19px;
  color: #fbdc4b;
  text-align: center;
  padding: 0px;
  background: transparent !important;
  margin-left: -25px;
}
.tag-bet-input-div:focus-visible {
  box-shadow: none;
  outline: navajowhite;
}

.tag-bet-input-price {
  position: absolute;
  left: -0.5px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: grid;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: transparent;
  pointer-events: none;
  opacity: 1;
}
.fly_line_animation {
  position: absolute;
  bottom: 0px;
  width: 80%;
  max-width: 80%;
  transition: all 1.5s ease-in-out;
}

@keyframes flyComplate {
  0% {
    bottom: 44%;
    left: 65%;
  }
  10% {
    bottom: 50%;
    left: 100%;
  }
  100% {
    bottom: 45%;
    left: 100%;
  }
}
.fly_main {
  position: absolute;
  width: 75%;
  margin-top: 40%;
  margin-left: -68%;
}
.fly_main_fire {
  position: absolute;
  transition: all 0.32s ease 0s;
  transform: rotate(7deg);
  width: 100%;
}
.fly_imgs_box {
  position: relative;
  display: grid;
  place-items: center;
  -moz-box-align: center;
  width: 100%;
  padding-top: 100%;
  transition: all 0.32s ease 0s;
  animation: 20s ease 0s infinite normal none running shake;
}
#ReferAndEarn .modal-dialog {
  margin: 1.5rem auto;
  max-width: 760px;
}
#AmountDeposite .modal-dialog {
  margin: 6.5rem auto;
  max-width: 500px;
  /* margin-top: 0%; */
}
.refer-amount h3 {
  text-align: center;
  font-weight: 600;
  font-size: 28px;
}
.refer-code {
  text-align: center;
  position: relative;
}
.refer-code img {
  position: relative;
}
.refer-code p {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 164px;
  top: 32px;
  color: #fff;
}
.refer-code-text {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.refer-code-text p {
  width: 502px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}
.navbar-logo a img {
  height: 100%;
  width: 100%;
  max-width: 100px;
  max-height: 100px;
}
.refer-process {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
}

.refer-process-work img {
  border: 2px solid #ffd700;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  padding: 8px;
  z-index: 9999999;
  background-color: #222d47;
}
.refer-process-work p {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.refer-process-work {
  position: relative;
  width: 33%;
}
.refer-process-work:nth-child(1)::after {
  content: "";
  height: 30px;
  width: 100%;
  background: url(../public/images/referline.png) no-repeat;
  top: 23px;
  right: -137px;
  position: absolute;
  display: inline-block;
}
.refer-process-work:nth-child(2)::after {
  content: "";
  height: 30px;
  width: 100%;
  background: url(../public/images/referline1.png) no-repeat;
  top: 23px;
  right: -137px;
  position: absolute;
  display: inline-block;
}

@keyframes shake {
  0% {
    transform: translate(5px, 5px) rotate(0deg);
  }
  10% {
    transform: translate(-10px, -15px) rotate(-3deg);
  }
  20% {
    transform: translate(10px) rotate(3deg);
  }
  30% {
    transform: translate(4px, 10px) rotate(1deg);
  }
  40% {
    transform: translate(20px, -8px) rotate(5deg);
  }
  50% {
    transform: translate(-10px, 34px) rotate(-5deg);
  }
  60% {
    transform: translate(-0px, 50px) rotate(0deg);
  }
  70% {
    transform: translate(1px, 50px) rotate(-4deg);
  }
  80% {
    transform: translate(-15px, 20px) rotate(5deg);
  }
  90% {
    transform: translate(34px, 10px) rotate(0deg);
  }
  100% {
    transform: translate(10px, 10px) rotate(0deg);
  }
}

@media (max-width: 1300px) {
  .fly {
    width: 160px;
    height: 160px;
  }
  .bet_checkout {
    font-size: 14px !important;
    font-weight: 600;
  }
}
@media (max-width: 500px) {
  #Batting_section {
    min-height: 95%;
  }
  .batting-detail {
    width: 96%;
    margin-bottom: 80px;
  }
  @keyframes fly {
    0% {
      bottom: -7%;
      left: -4%;
    }
    2% {
      bottom: -8%;
      left: 0%;
    }
    10% {
      bottom: 54%;
      left: 65%;
    }
    100% {
      bottom: 54%;
      left: 65%;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .batting_count-detail {
    width: 69%;
  }
  .bet_content-btn {
    width: 29%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  #Container {
    padding: 20px 5px;
  }
  .batting_count-detail {
    width: 69%;
  }
  .bet_content-btn {
    width: 29%;
  }
  .play_batting_inr {
    column-gap: 0px;
    padding: 2px 3px;
  }
  .right_bg {
    padding: 5px;
  }
  .bets_content {
    padding: 10px 5px;
    margin-bottom: 15px;
  }
  .top_bets_content {
    margin-bottom: 16px;
  }
  .Register.LoginperonalInformation .button-section {
    position: relative;
    right: 5px;
    bottom: -45px;
  }

  .Register .form-control {
    width: 100% !important;
    margin-left: 15px;
  }
  .Register .form-group.floating > label {
    left: 15px;
    background: #1b1c1d !important;
  }
  .bets-title {
    padding: 15px 15px;
  }
}
@media (max-width: 1300px) {
  .user_title {
    font-size: 14px;
  }

  .play_batting_inr p {
    line-height: 16px;
  }
  .loadimg-images .loadingRocketSuccessfull_ {
    width: 30px;
    height: 60px;
  }
  #Container h1 {
    font-size: 18px;
  }
  .progress-wrapper {
    width: 350px;
    height: 9px;
  }
  p {
    font-size: 14px;
  }
  .progress {
    height: 3px;
  }
  .loading.loadingOnProgress {
    height: 120px;
    width: 120px;
  }

  .loadimg-images {
    height: 100px;
    width: 100px;
  }
  .number_inputs .number_input {
    padding: 20px 20px;
    max-width: 66px;
    max-height: 66px;
    font-size: 16px;
    margin: 0px 4px;
  }
  .Register .form-group.floating {
    height: 66px;
  }
  .login_content .form-section {
    justify-content: unset;
  }
  .form-group.floating {
    margin: 14px 0px;
  }
  .forgot_password {
    bottom: -48px;
  }
  .login_content2 {
    max-height: 368px;
  }

  .login_option_content {
    padding: 55px 47px 5px;
  }
  #Battingtables .table tbody tr {
    font-size: 14px;
  }
  .bets-title {
    display: flex;
  }
  button.main-btn.google_btn img {
    height: 40px;
    width: 40px;
  }
  button.main-btn.facebook_btn img {
    height: 40px;
    width: 40px;
  }
  .login_option .support_btns .main-btn h5 {
    font-size: 16px;
  }
  .login_option .support_btns .main-btn h5 {
    margin: 0px;
  }
  .login_informention {
    margin: 30px 0px;
  }
  .bets-title h5 {
    text-align: center;
    /* margin-bottom: 15px; */
  }
  .batting-btns-bar {
    justify-content: center;
  }
  .login_left {
    padding: 10px;
  }
  .top_batting_section {
    padding: 20px 10px;
  }
  .Collect h5 {
    font-size: 14px;
  }
  .Collect h4 {
    font-size: 14px;
  }
  .main-btn.calendar-btn span {
    margin-right: 3px;
    font-size: 12px;
  }
  .top_bets_content .user_title {
    font-size: 14px;
  }
  .bet_amount {
    font-size: 12px;
  }
  .topbatting_section .user_title {
    width: 121px;
    align-items: center;
  }
  h3.user_name {
    font-size: 18px;
    margin-left: 8px;
  }
  .main-btn.user-btn {
    font-size: 14px;
  }
  .main-btn.deposit-btn {
    font-size: 14px;
  }
  span#BattingBattingBtn {
    font-size: 18px;
  }
  .battling-button.twodown {
    font-size: 13px;
  }

  #battingTitle.active {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {
  .support_btns .main-btn {
    column-gap: 14px;
  }

  #AddressInfo .register_right_bottom,
  #PersonalInfo .register_right_bottom {
    position: absolute;
    bottom: 181px;
    left: -10px;
  }
  .Register .form-control {
    width: 100% !important;
    margin-left: 15px;
  }
  .Register .form-group.floating > label {
    left: 15px;
    background: #1b1c1d !important;
  }
}

@media (max-width: 1100px) {
  .button-section {
    position: relative;
    right: 5px;
    bottom: -77px;
  }
  .login_option2 {
    max-height: 300px;
    position: relative;
    top: -20px;
  }
}
@media (max-width: 992px) {
  .rightSide {
    margin-top: 0px !important;
    transform: translate3d(0px, 0, 0) !important;
    opacity: 1 !important;
  }
  div#BetsSection {
    margin-top: 0px;
  }
  .login_right_img_section {
    display: none;
  }
  .login_right::before {
    display: none;
  }
  .login_right::after {
    display: none;
  }
  .login_right {
    padding: 83px 30px 0px 30px;
    row-gap: 0px;
    background: unset;
  }
  #AddressInfo .login_left {
    height: 100%;
  }
  .login_left .close_page {
    top: -125px;
    right: 12px;
  }
  .Register.LoginperonalInformation .login_content2 {
    min-height: 826px;
  }
  .Register .form-control {
    width: 100% !important;
    margin-left: 43px;
  }
  .Register .form-group.floating > label {
    left: 48px;
    background: #1b1c1d !important;
  }
  .Register.LoginperonalInformation .button-section {
    position: relative;
    right: 5px;
    bottom: -96px;
  }
  .bts_section {
    min-height: 300px;
  }
}
.text-end-center {
  text-align: end;
}
.navbar-right-menu-icon {
  display: none;
}
@media only screen and (min-width: 767px) and (max-width: 830px) {
  .batting_count-detail {
    width: 69%;
  }
  .bet_content-btn {
    width: 29%;
  }
}
@media (max-width: 768px) {
  #Container {
    min-height: 777px;
    overflow: auto;
  }
  .deposit {
    display: none;
  }
  .navbar-right-menu-icon i {
    font-size: 24px;
  }
  .fly_main {
    position: absolute;
    width: 54%;
    margin-top: 36%;
    margin-left: -68%;
  }
  .fly_main_fire {
    position: absolute;
    transition: all 0.32s ease 0s;
    transform: rotate(7deg);
    width: 80%;
  }

  .navbar-right-close-icon {
    position: absolute;
    right: 49px;
    z-index: 2;
    top: 27px;
  }
  .navbar-right-menu-icon {
    display: block;
  }
  .text-end-center {
    text-align: start;
  }
  .progress-wrapper {
    width: 300px;
    height: 14px;
    background-color: #222d47;
    outline: 1px solid #2a3763;
    position: relative;
    border-radius: 7px;
    margin: 7px;
  }
  span#BattingBattingBtn {
    font-size: 14px;
  }
  .batting-btns-bar .login-btn {
    height: 36px;
  }
  .batting-btns-bar .login-btn-img {
    height: 26px;
  }
  .login-btn {
    height: 36px;
    font-size: 14px;
  }
  .main-btn.deposit-btn {
    margin-left: 0px;
  }
  .login-btn .login-btn-img {
    height: 26px;
  }

  /* video section responsive */
  .batiing-video {
    min-height: 300px;
  }
  .plane {
    animation: cloudd 100s linear;
    margin: -5% -5% 0 0;
  }
  @keyframes cloudd {
    0% {
      left: 0%;
      bottom: -5%;
      transform: none;
    }
    1% {
      left: 12%;
      bottom: -5%;
      transform: none;
    }
    1.2% {
      left: 13%;
      bottom: -4%;
      transform: rotate(-35deg);
    }
    17% {
      left: 70%;
      bottom: 50%;
      transform: rotate(-35deg);
    }
    100% {
      left: 70%;
      bottom: 50%;
      transform: rotate(-35deg);
    }
  }
  .right_bg {
    margin-bottom: -11px;
  }
}
@media (max-width: 600px) {
  .deposit-right-top.d-flex.justify-content-between.align-items-center h4 {
    font-size: 16px;
  }
  .deposit-right-top .id {
    font-size: 14px;
  }
  .upi_id .upi_text {
    font-size: 16px;
  }
  .input.inputID {
    font-size: 16px;
  }
  .upi_id .copy-btn {
    height: 32px;
    width: 95px;
  }
  buttom.main-btn.login-btn.copy-btn img {
    height: 25px;
    width: 25px;
  }
  .upi_id h5 {
    font-size: 16px;
  }
  .deposit_options.active {
    padding: 15px;
  }
  .modal-dialog .form-group.floating.uploadImg > label {
    font-size: 12px;
  }
  .deposit_options img {
    width: 100px;
  }
  div#upload-file img {
    height: 25px;
    width: 25px;
  }
  div#mailIMGShoe img {
    height: 25px;
    width: 25px;
  }
  .deposit_options {
    padding: 15px;
  }
  .history_list_detail img {
    height: 49px;
    width: 100%;
  }

  .batiing-video {
    min-height: 300px;
  }
  .plane {
    /* animation: clouddd 40s linear; */
    margin: -5% -5% 0 0;
  }
  @keyframes clouddd {
    0% {
      left: 0%;
      bottom: -10%;
      transform: none;
    }
    4% {
      left: 5%;
      bottom: -10%;
      transform: none;
    }
    5% {
      left: 6%;
      bottom: -9%;
      transform: rotate(-35deg);
    }
    17% {
      left: 65%;
      bottom: 55%;
      transform: rotate(-35deg);
    }
    100% {
      left: 65%;
      bottom: 55%;
      transform: rotate(-35deg);
    }
  }
}
@media (max-width: 560px) {
  .batting_count-detail {
    width: 69%;
  }
  .bet_content-btn {
    width: 29%;
  }
}
@media (max-width: 500px) {
  #Container {
    padding: 20px 5px;
  }
  .welcome_content h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }
  .welcome_content h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .login_option_content {
    padding: 55px 12px 5px;
  }

  .login_option .support_btns .main-btn {
    column-gap: 0;
  }
  .login_container .row {
    height: auto;
  }
  .login_right {
    padding: 10px;
  }
  .login_left .close_page {
    top: -56px;
    right: 0px;
  }
  .login_left .close_page img {
    width: 20px;
    height: 20px;
  }
  .login_left {
    padding: 0px 5px;
  }
  .login_informention {
    margin: 20px 0px;
  }
  .login_content {
    padding: 15px;
  }
  .login_content .form-section {
    top: 0px;
  }
  .login_informention .mail_img {
    min-width: 27.29px;
    min-height: 27.29px;
  }
  .login_informention .mail_img img {
    width: 16px;
    height: 12px;
  }

  .form-group.floating > label {
    left: 49px;
  }
  .form-control.floating:valid ~ label {
    transform: translateY(-27px) scale(0.8);
  }
  .forgot_password {
    bottom: -29px;
    right: 17px;
  }
  .button-section {
    position: relative;
    right: 5px;
    bottom: -61px;
  }
  .next_button .main-btn.next-page {
    width: 34px;
    height: 34px;
    padding: 0;
  }
  .next_button .main-btn.next-page img {
    width: 15.93px;
    height: 10.56px;
  }
  .login_option_content {
    padding: 55px 22px 5px;
    row-gap: 30px;
  }
  .login_option_content h5 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  button.main-btn.google_btn img,
  button.main-btn.facebook_btn img {
    height: 26px;
    width: 26px;
  }
  .login_option .support_btns .main-btn h5 {
    font-size: 12px;
    margin: auto;
  }
  .support_btns h5 {
    margin: auto;
  }
  button.main-btn.login-btn.user-btn {
    display: none;
  }
  .show-icon-respon {
    display: block;
    font-size: 28px;
    margin-top: 5px;
  }
  .user-detail-popup::after {
    right: 1px;
  }
  .user-detail-popup {
    right: 6px;
  }
  .user-detail-popup {
    width: 300px;
  }
}
@media (max-width: 410px) {
  .bets-title {
    padding: 15px 15px;
  }
  #Container h1 {
    font-size: 20px;
  }
  p {
    font-size: 12px;
  }
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
    font-size: 10px;
  }
  .progress-wrapper {
    width: 200px;
  }
  .loadimg-images .loadingOnProgress_ {
    width: 100px;
    height: 100px;
  }
  .loadimg-images .loadingRocketSuccessfull_ {
    width: 30px;
    height: 64px;
  }
  .batiing-video {
    min-height: 300px;
  }
  .plane {
    /* animation: cloudddd 40s linear; */
    margin: -5% -5% 0 0;
  }
  @keyframes cloudddd {
    0% {
      left: 0%;
      bottom: -10%;
      transform: none;
    }
    4% {
      left: 5%;
      bottom: -10%;
      transform: none;
    }
    5% {
      left: 6%;
      bottom: -9%;
      transform: rotate(-35deg);
    }
    17% {
      left: 45%;
      bottom: 45%;
      transform: rotate(-35deg);
    }
    100% {
      left: 45%;
      bottom: 45%;
      transform: rotate(-35deg);
    }
  }
}
